import {
    SET_COMPANIES,
    SET_COMPANY_USERS,
    SET_COMPANY,
    SET_COMPANY_LISTING_PAGINATION,
    SET_COMPANY_USER_LISTING_PAGINATION,
    SET_SELECTED_COMPANY
  } from "../../types/superAdmin.json"

const itemsPerPage = 10
const initialState = {
    companiesData: {
        pagination: {
            page: 1,
            totalItems: 0,
            itemsPerPage,
            pageData: {}
        },
        companies: {}
    },
    companyUsersData: {
        selectedCompanyUuid: null,
        pagination: {
            page: 1,
            totalItems: 0,
            itemsPerPage,
            pageData: {}
        },
        companyUsers: {}
    }
}
const setCompanies = (state, companiesData) => {
    const {companies, totalItems, itemsPerPage, page} = companiesData

    const newPagination = {
        ...state.companiesData.pagination,
        page: page || state.companiesData.pagination.page,
        itemsPerPage: itemsPerPage || state.companiesData.pagination.itemsPerPage,
        totalItems: totalItems || state.companiesData.pagination.totalItems
    }

    let newPageData
    let newCompanies
    if (page === 1 && newPagination.itemsPerPage !== itemsPerPage) {
        newPageData = {
            [newPagination.page]: Object.keys(companies)
        }
        newCompanies = {...companies}
    } else {
        newPageData = {
            ...state.companiesData.pagination.pageData,
            [newPagination.page]: Object.keys(companies)
        }
        newCompanies = {...state.companiesData.companies, ...companies}
    }

    return {
        ...state,
        companiesData: {
            ...state.companiesData,
            pagination: {
                ...newPagination,
                pageData: newPageData
            },
            companies: newCompanies
        }
    }
}

const setCompanyListingPagination = (state, data) => {
    const {itemsPerPage, page} = data
    return {
        ...state,
        companiesData: {
            ...state.companiesData,
            pagination: {
                ...state.companiesData.pagination,
                page,
                itemsPerPage
            }
        }
    }
}

const setCompany = (state, company) => {
    const {uuid} = company
    return {
        ...state,
        companiesData: {
            ...state.companiesData,
            companies: {
                ...state.companiesData.companies,
                [uuid]: company
            }
        }
    }
}

const setCompanyUsers = (state, companyUsersData) => {
    const {companyUuid, users, page, itemsPerPage, totalItems} = companyUsersData

    const newPagination = {
        ...state.companyUsersData.pagination,
        page: page || state.companyUsersData.pagination.page,
        itemsPerPage: itemsPerPage || state.companyUsersData.pagination.itemsPerPage,
        totalItems: totalItems || state.companyUsersData.pagination.totalItems
    }

    let newPageData
    const newCompanyUsers = {...state.companyUsersData.companyUsers}
    
    if (page === 1 && newPagination.itemsPerPage !== itemsPerPage) {
        newPageData = {
            [newPagination.page]: Object.keys(users)
        }
        newCompanyUsers[companyUuid] = users
    } else {
        newPageData = {
            ...state.companyUsersData.pagination.pageData,
            [newPagination.page]: Object.keys(users)
        }
        newCompanyUsers[companyUuid] = {...(newCompanyUsers[companyUuid] || {}), ...users}
    }

    return {
        ...state,
        companyUsersData: {
            ...state.companyUsersData,
            pagination: {
                ...newPagination,
                pageData: newPageData
            },
            companyUsers: newCompanyUsers
        }
    }
}

const setCompanyUserListingPagination = (state, data) => {
    const {itemsPerPage, page} = data
    return {
        ...state,
        companyUsersData: {
            ...state.companyUsersData,
            pagination: {
                ...state.companyUsersData.pagination,
                page,
                itemsPerPage
            }
        }
    }
}

const setSelectedUserCompany = (state, data) => {
    return {
        ...state,
        companyUsersData: {
            ...state.companyUsersData,
            selectedCompanyUuid: data.companyUuid
        }
    }
}

const superAdminStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANIES:
        return setCompanies(state, action.data)
    case SET_COMPANY:
        return setCompany(state, action.data)
    case SET_COMPANY_USERS:
        return setCompanyUsers(state, action.data)
    case SET_COMPANY_LISTING_PAGINATION:
        return setCompanyListingPagination(state, action.data)
    case SET_COMPANY_USER_LISTING_PAGINATION:
        return setCompanyUserListingPagination(state, action.data)
    case SET_SELECTED_COMPANY:
        return setSelectedUserCompany(state, action.data)
    default:
      return state
  }
}

export default superAdminStateReducer
