// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: ''
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val }
    case 'GET_BOOKMARKS':
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case 'UPDATE_BOOKMARKED': {
      const newSuggestions = state.suggestions.map(item => {
        if (item.id === action.id) {
          return { ...item, isBookmarked: !item.isBookmarked }
        }
        return item
      })

      const objectToUpdate = newSuggestions.find(item => item.id === action.id)

      let newBookmarks = [...state.bookmarks]
      const bookmarkIndex = newBookmarks.findIndex(x => x.id === action.id)

      if (bookmarkIndex === -1) {
        newBookmarks.push(objectToUpdate)
      } else {
        newBookmarks = newBookmarks.filter(x => x.id !== action.id)
      }

      return {
        ...state,
        suggestions: newSuggestions,
        bookmarks: newBookmarks
      }
    }
    default:
      return state
  }
}

export default navbarReducer
